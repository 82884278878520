import React from "react"
import Layout from "components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "components/SEO"
import { Link } from "gatsby"

const success = () => {
  return (
    <div>
      <Seo title="Payment Success | Mandatory Insects" />
      <Layout>
        <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
          <div className="lg:w-10/12 w-full">
            <h2 className="xl:w-8/12 lg:w-10/12 w-full font-bold text-gray-800 lg:text-4xl text-3xl lg:leading-10 leading-9 mt-2">
              Thank you for{" "}
              <span className="block text-lime-600 xl:inline">
                your purchase
              </span>
            </h2>

            <h3 className="text-gray-800 text-xl mt-5">
              Live stock will be sent out on Mondays and Tuesdays of each week.
            </h3>
            <p className="font-normal text-base leading-6 text-gray-600 mt-6">
              Any question? please contact us.{" "}
              <Link to="/contact-us/">Here</Link>
            </p>

            <h4 className="font-normal text-xl leading-6 text-gray-600 mt-6">
              Matt Upson
            </h4>
            <span className="text-lime-700">Mandatory Insects</span>
          </div>

          <div className="lg:mt-14 sm:mt-10 mt-12">
            <StaticImage
              src="../components/images/Logo/mandatoryinsectslogo.png"
              alt="Mealworms"
              layout="constrained"
              placeholder="blurred"
              width={400}
            />
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default success
